html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

// VARIABLES
:root {
  --title-font-size: 55px;
  --subtitle-font-size: 30px;

  --title-font-size-mobile: 40px;
  --subtitle-font-size-mobile: 20px;

  --text-color: #003555;
  // --global-text-color: #222;
  // --global-primary-title-size: 2rem;
  // --global-color-primary: #88498f;
  // --global-color-secondary: #779fa1;
  // --global-color-warning: #e28413;
  // --global-color-danger: #ff6542;
}

.component-layout {
  padding: 30px 16px 55px 16px;
}

.component-layout-border {
  border-top: 5px dotted #87cdec;
  border-top-style: double;
  border-bottom: 5px dotted #87cdec;
  border-bottom-style: double;
  background-color: #fcfcfc;
}

@media (min-width: 600px) {
  .component-layout {
    padding: 30px 17% 55px 17%;
  }
}

//Bild am Anfang einer Seite
.intro-pic-container {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  img {
    //Das Foto das für den Hintergrund benutzt wird^
    position: absolute !important;
    z-index: -1 !important;
    max-height: 400px;
    width: 100%;
    margin-top: 30px !important; //wegen padding vom component-layout
  }

  .title {
    font-size: 3rem;
    color: white;
    text-align: center;
    text-shadow: 0px 1px 6px black;
  }
}

@media (min-width: 1000px) {
  .intro-pic-container {
    .title {
      font-size: 6rem;
    }
  }
}
